// config.js

export const siteInfo = {
  companyName: 'Whiskey Throttle Cafe',
  address: '12089 SW Red Cloud Rd. Powell Butte, Oregon',
  phone: '541-316-0247',
  email1: 'braaap@whiskeythrottlecafe.com',
  email2: 'hello@whiskeythrottlecafe.com',
  about1:
    'As a family of riders who have raced over the years, we understand how hectic it can be to get the family fed before they need to be on the line. We recognized the need for a quality, yet affordable food option for racers and spectators alike. Thats where the idea of Whiskey Throttle Cafe was born.',
  about2:
    'Whether you are gearing for the starting line or just looking to wind down and grab a bite to eat, Whiskey Throttle Cafe has you covered with a menu that is designed to satisfy the hunger of riders and spectators alike.',
  bio1: 'Whiskey Throttle Cafe is your go-to pit stop for exceptional food in the heart of Oregons dirt-bike community. We have crafted a simple menu thats designed to satisfy the hunger of riders and spectators alike. Our commitment to quality ingredients and delicious flavors ensures that every bite is a memorable one.',
  bio2: 'Whiskey Throttle Cafe is your homegrown mobile pit stop firing up tasty fuels at motorsports gatherings across Oregon and beyond. Our menu focused on easy to eat rider favorites - hot bites cooked fresh to power your adventures on the go. As passionate members of the motorsports community ourselves, we travel to races, poker runs, demo days, private events and more to serve quick, affordable eats that satisfy those epic event appetites. Whether joining us trackside or fireside, our happy fans know and love us as the family-owned fueling pitstop that brings delicious comfort foods and community to every motorsports occasion.',
};

export const socialMediaLinks = {
  facebook: 'https://www.facebook.com/whiskeythrottlecafe',
  instagram: 'https://www.instagram.com/whiskeythrottlecafe/',
};

export const sections = [
  { id: 1, name: 'Home', link: '#top' },
  { id: 2, name: 'About', link: '#about' },
  { id: 3, name: 'Menu', link: '#menu' },
  { id: 4, name: 'Contact', link: '#contact' },
];

const baseImageURL =
  'https://whiskey-throttle-cafe.s3.us-west-2.amazonaws.com/assets/img';

export const images = {
  contactBackground: `${baseImageURL}/riders/l.webp`,
  navHoverIcon: `${baseImageURL}/nav-hover-icon/s.webp`,
  coffee: `${baseImageURL}/black-coffee/s.webp`,
  sandwich: `${baseImageURL}/breakfast-sandwich/s.webp`,
  hotdog: `${baseImageURL}/hotdog/s.webp`,
  sausage: `${baseImageURL}/sausage/s.webp`,
  quesadilla: `${baseImageURL}/quesadilla/s.webp`,
  snacks: `${baseImageURL}/snacks/s.webp`,
  footerBackground: `${baseImageURL}/footer-background/xxl.webp`,
  bannerLogo: `${baseImageURL}/logo-white-distressed/s.webp`,
  footerLogo: `${baseImageURL}/logo-white-distressed/s.webp`,
  ctaLowerTransition: `${baseImageURL}/cta-lower-transition/s.webp`,
  bannerBackground: `${baseImageURL}/banner-background/m.webp`,
  bannerLowerTransition: `${baseImageURL}/banner-bottom/xxl.webp`,
  topoBackground: `${baseImageURL}/topo-background/l.webp`,
  aboutImage: `${baseImageURL}/about-us/l.webp`,
  aboutBackgroundLogo: `${baseImageURL}/logo-green-distressed/s.webp`,
};
